import { render, staticRenderFns } from "./CookieConsent.vue?vue&type=template&id=7b12ad78&scoped=true"
import script from "./CookieConsent.vue?vue&type=script&lang=js"
export * from "./CookieConsent.vue?vue&type=script&lang=js"
import style0 from "./CookieConsent.vue?vue&type=style&index=0&id=7b12ad78&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b12ad78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsCookieForm: require('/src/components/organisms/CookieForm.vue').default,MoleculesButtonPill: require('/src/components/molecules/button/Pill.vue').default,AtomsLink: require('/src/components/atoms/Link.vue').default})
