export const SIZE_LARGE = 'large';
export const SIZE_MEDIUM = 'medium';
export const SIZE_SMALL = 'small';
export const SIZE_EXTRA_SMALL = 'x-small';

export const COLOR_SCHEME_WHITE = 'white';
export const COLOR_SCHEME_BLACK = 'black';
export const COLOR_SCHEME_LIGHT = 'light';
export const COLOR_SCHEME_DARK = 'dark';
export const COLOR_SCHEME_BLUE = 'blue';
export const COLOR_SCHEME_TRANSPARENT = 'transparent';
export const COLOR_SCHEME_COLOR = 'color';
export const COLOR_SCHEME_YELLOW = 'yellow';
export const COLOR_SCHEME_GREEN = 'green';
export const COLOR_SCHEME_RED = 'red';
export const COLOR_SCHEME_GRAY = 'gray';
export const COLOR_SCHEME_TURQUOISE = 'turquoise';

export const VIDEO_TYPE_VIMEO = 'vimeo';
export const VIDEO_TYPE_YOUTUBE = 'youtube';
export const VIDEO_TYPE_FILE = 'file';

export const ALIGNMENT_LEFT = 'left';
export const ALIGNMENT_RIGHT = 'right';
