
import { bool, oneOf, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    size: oneOf([
      'small',
      'small-medium',
      'medium',
      'medium-large',
      'large',
    ]).def('large'),
    tag: string().def('div'),
    padding: bool().def(true),
  },
};
