
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
export default {
  props: {
    heading: oneOfType([string(), nullable()]),
    title: oneOfType([string(), nullable()]),
    section: oneOfType([string(), nullable()]),
    overviewImage: arrayOf(shape(imageShape).loose),
    url: string(),
  },
};
