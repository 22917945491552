
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    category: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        title: oneOfType([string(), nullable()]),
        colorName: oneOfType([string(), nullable()]),
      }).loose
    ),
  },
  computed: {
    categoryLabel() {
      return this.category.map((category) => category.title).join(' · ');
    },
    color() {
      return this.$first(this.category)?.colorName;
    },
  },
};
