export const state = () => ({
  width: 0,
  height: 0,
  availWidth: 0,
  availHeight: 0,
  scrollY: 0,
  scrollingTo: false,
});

export const mutations = {
  resize(state, viewport) {
    state.width = viewport.width;
    state.height = viewport.height;
    state.availWidth = viewport.availWidth;
    state.availHeight = viewport.availHeight;
  },
  scroll(state, value) {
    state.scrollY = value;
  },
  scrollingTo(state, value) {
    state.scrollingTo = value;
  },
};
