
import { bool, nullable, oneOfType, string } from 'vue-types';
import modulesMixin from '@/mixins/modules';

export default {
  mixins: [modulesMixin],
  inheritAttrs: false,
  props: {
    startBg: oneOfType([string(), nullable()]),
    endBg: oneOfType([string(), nullable()]),
    headerBottomBg: string().def(''),
    ankeilerHeader: bool().def(false),
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    components() {
      return this.modules?.map((module) => this.getType(module));
    },
    viewportWidth() {
      return this.$store.state.viewport.width;
    },
  },
  watch: {
    viewportWidth() {
      if (this.isMounted) {
        this.calculateModulePositions(this.ankeilerHeader);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.calculateModulePositions(this.ankeilerHeader);
      }, 2000);
      this.isMounted = true;
    });
  },
};
