
import { bool, oneOf, string } from 'vue-types';
import { COLOR_SCHEME_BLUE, COLOR_SCHEME_WHITE } from '~/constants/General';

const TRANSITION_DURATION = 400;

export default {
  props: {
    active: bool().def(false),
    fullHeight: bool().def(false),
    modalClass: string().def(''),
    colorScheme: oneOf([COLOR_SCHEME_BLUE, COLOR_SCHEME_WHITE]).def(
      COLOR_SCHEME_WHITE
    ),
    wrapperClass: string(),
  },
  data() {
    return {
      render: false,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_WHITE,
    };
  },
  computed: {
    wrapperClasses() {
      return [
        !this.active ? 'scale-90 opacity-0' : null,
        this.fullHeight ? 'h-full' : null,
        this.wrapperClass,
      ];
    },
    modalClasses() {
      return [
        this.fullHeight ? 'h-full' : null,
        this.colorScheme === COLOR_SCHEME_BLUE ? 'bg-blue-500' : 'bg-white',
        this.modalClass,
      ];
    },
  },
  watch: {
    active(value) {
      if (value) {
        this.render = true;
      } else {
        setTimeout(() => {
          this.render = false;
        }, TRANSITION_DURATION);
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('overlays/closeAll');
    },
  },
};
