export default (context) => {
  const viewport = {
    storeValues() {
      context.store.commit('viewport/resize', {
        width: window.innerWidth,
        height: window.innerHeight,
        availWidth: window.screen.availWidth,
        availHeight: window.screen.availHeight,
      });
    },
    onResize() {
      window.requestAnimationFrame(this.storeValues);
    },
    listenForResize() {
      window.addEventListener('resize', this.onResize.bind(this));
    },
    init() {
      this.storeValues();
      this.listenForResize();
    },
  };

  if (process.env.STORYBOOK) {
    viewport.init();
  } else {
    window.onNuxtReady(() => {
      viewport.init();
    });
  }
};
