
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
export default {
  props: {
    heading: oneOfType([string(), nullable()]),
    entries: arrayOf(
      shape({
        id: string(),
        section: string(),
        title: string(),
      }).loose
    ),
    categories: arrayOf(
      shape({
        id: string(),
        url: string(),
        title: string(),
      }).loose
    ),
  },
};
