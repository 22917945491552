import { ClientError } from 'graphql-request';

export default ({ app, error, store, i18n, $config }, inject) => {
  function actionUrl(path) {
    return `${$config.CMS_URL}/index.php?p=${i18n.locale}/actions${path}`;
  }

  function getCsrfToken() {
    if (!store.state.csrfToken?.value) {
      try {
        return app.$axios.$get(actionUrl('/main/csrf/token')).then((token) => {
          if (token.name && token.value) {
            if (process.client) {
              store.commit('csrfToken', token);
            }
            return token;
          }
        });
      } catch (e) {
        console.error(e);
        this.$sentry.captureException(e);
      }
    } else {
      return store.state.csrfToken;
    }
  }

  async function updateRequest(method, path, payload) {
    const url = actionUrl(path);
    const token = await getCsrfToken();
    let extendedPayload = null;

    if (payload instanceof FormData) {
      payload.append(token.name, token.value);
      extendedPayload = payload;
    } else {
      extendedPayload = { ...payload, [token.name]: token.value };
    }

    return app.$axios[method](url, extendedPayload, {
      headers: { 'X-CSRF-Token': token.value },
    });
  }

  inject('cms', {
    actionUrl,
    async query({ query, variables, mandatoryKeys = [] }) {
      let response;
      let notFound = false;

      try {
        response = await app.graphQLClient.request(query, variables);
      } catch (e) {
        const message = e instanceof ClientError ? e.response : e;

        console.error(message);

        return null;
      }

      mandatoryKeys.forEach((key) => {
        if (!response[key]) {
          notFound = true;
        }
      });
      if (notFound) {
        return error({ statusCode: 404, message: 'Not found' });
      }
      return response;
    },
    get(path, payload) {
      const url = actionUrl(path);
      return app.$axios.$get(url, payload);
    },
    put(path, payload) {
      return updateRequest('$put', path, payload);
    },
    post(path, payload) {
      return updateRequest('$post', path, payload);
    },
  });
};
