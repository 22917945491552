import { render, staticRenderFns } from "./Pill.vue?vue&type=template&id=605b1bec&scoped=true"
import script from "./Pill.vue?vue&type=script&lang=js"
export * from "./Pill.vue?vue&type=script&lang=js"
import style0 from "./Pill.vue?vue&type=style&index=0&id=605b1bec&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605b1bec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesIconWithBackslash: require('/src/components/molecules/IconWithBackslash.vue').default,AtomsButton: require('/src/components/atoms/Button.vue').default})
