
import { object } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    error: object(),
  },
  mounted() {
    console.log(this.error);
    console.log(this.$route);
  },
};
