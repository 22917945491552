import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=3c9ef62c"
import script from "./Modal.vue?vue&type=script&lang=js"
export * from "./Modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/src/components/atoms/Icon.vue').default,MoleculesButtonCircle: require('/src/components/molecules/button/Circle.vue').default,OrganismsOverlayBase: require('/src/components/organisms/overlay/Base.vue').default})
