
import { bool } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    dark: bool().def(false),
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    locales() {
      return this.$i18n.locales;
    },
    hrefs() {
      const pageHrefs = this.$store.state.languageSwitch;

      const hrefs = {};
      this.$i18n.locales.forEach((locale) => {
        hrefs[locale.code] =
          pageHrefs[locale.code] || this.switchLocalePath(locale.code);
      });

      return hrefs;
    },
  },
  methods: {
    isCurrent(locale) {
      return locale === this.currentLocale;
    },
  },
};
