import Vue from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { CustomEase } from 'gsap/CustomEase';
// import { SplitText } from 'gsap/SplitText';

import Easings from '@/constants/Easings';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
// gsap.registerPlugin(SplitText);

for (const key in Easings) {
  CustomEase.create(key, Easings[key]);
}

// gsap.defaults({
//   ease: Easings.default,
// });

const GSAP = {
  install(Vue, options) {
    Vue.prototype.$gsap = gsap;
    Vue.prototype.$ScrollTrigger = ScrollTrigger;
    // Vue.prototype.$SplitText = SplitText;
  },
};

Vue.use(GSAP);
