export default function({ store, from, route, req }) {
  const userAgent = req?.headers['user-agent']?.toLowerCase();
  const botUserAgents = ['datadog', 'synthetics'];

  const enableTracking =
    !userAgent || botUserAgents.every((bot) => !userAgent.includes(bot));

  store.commit('enableTracking', enableTracking);
  store.commit('nav/pageColorPositions', []);

  // reset scroll depth tracking
  store.commit('scroll/lockTracking');
  store.commit('scroll/resetTracked');

  // reset overlays
  store.commit('overlays/closeAll');

  if (
    /^careers___/.test(from?.name) &&
    /^careerCategories___/.test(route?.name) &&
    typeof window !== 'undefined'
  ) {
    location.hash = '#jobs';
  }
}
