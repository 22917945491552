
import { number, oneOf } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    height: number().def(32),
    background: oneOf(['color', 'dark', 'white']).def('white'),
  },
  computed: {
    width() {
      return this.height * 3.625;
    },
  },
};
