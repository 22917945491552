import { arrayOf, nullable, number, oneOfType, shape, string } from 'vue-types';

export const categoryShape = {
  id: string(),
  title: string(),
  slug: string(),
};

export const imageShape = {
  __typename: string(),
  id: string(),
  title: string(),
  kind: string(),
  src: string(),
  srcset: string(),
  mimeType: string(),
  width: number(),
  height: number(),
  focalPoint: arrayOf(number()).def([0.5, 0.5]),
  transforms: shape({
    __typename: string(),
    placeholderImage: string(),
    src: string(),
    srcWebp: string(),
    maxSrcsetWidth: number(),
    srcset: string(),
    srcsetWebp: string(),
  }).loose,
};

export const assetShape = {
  __typename: string(),
  id: string(),
  title: string(),
  kind: string(),
  src: string(),
  mimeType: string(),
};

export const entryShape = {
  __typename: string(),
  id: string(),
  title: string(),
  uri: oneOfType([string(), nullable()]),
  url: oneOfType([string(), nullable()]),
  slug: string(),
  section: string(),
  type: string(),
};

export const customLinkFieldShape = {
  __typename: string(),
  id: string(),
  type: string(),
  label: oneOfType([string(), nullable()]),
  locationEntry: arrayOf(shape(entryShape).loose),
  locationUrl: string(),
  asset: arrayOf(shape(assetShape).loose),
};
