
import { number, oneOf, string } from 'vue-types';
import IconArrowDown from '@/assets/icons/arrow-down.svg';
import IconContract from '@/assets/icons/contract.svg';
import IconArrowLeft from '@/assets/icons/arrow-left.svg';
import IconArrowRight from '@/assets/icons/arrow-right.svg';
import IconArrowUp from '@/assets/icons/arrow-up.svg';
import IconChevronDown from '@/assets/icons/chevron-down.svg';
import IconChevronLeft from '@/assets/icons/chevron-left.svg';
import IconChevronRight from '@/assets/icons/chevron-right.svg';
import IconChevronUp from '@/assets/icons/chevron-up.svg';
import IconClose from '@/assets/icons/close.svg';
import IconCheckmark from '@/assets/icons/checkmark.svg';
import IconEllipsis from '@/assets/icons/ellipsis.svg';
import IconEnvelope from '@/assets/icons/envelope.svg';
import IconExpand from '@/assets/icons/expand.svg';
import IconFacebook from '@/assets/icons/facebook.svg';
import IconGithub from '@/assets/icons/github.svg';
import IconGoogleMaps from '@/assets/icons/google-maps.svg';
import IconInstagram from '@/assets/icons/instagram.svg';
import IconLink from '@/assets/icons/link.svg';
import IconLinkedin from '@/assets/icons/linkedin.svg';
import IconMenu from '@/assets/icons/menu.svg';
import IconPhone from '@/assets/icons/phone.svg';
import IconPlay from '@/assets/icons/play.svg';
import IconPlus from '@/assets/icons/plus.svg';
import IconReturn from '@/assets/icons/return.svg';
import IconX from '@/assets/icons/x.svg';
import IconYoutube from '@/assets/icons/youtube.svg';
import IconLogoOnColor from '@/assets/icons/logo-on-color.svg';
import IconLogoOnDark from '@/assets/icons/logo-on-dark.svg';
import IconLogoOnWhite from '@/assets/icons/logo-on-white.svg';
import IconSearch from '@/assets/icons/search.svg';
import IconEnter from '@/assets/icons/enter.svg';
import IconUpload from '@/assets/icons/upload.svg';
import IconShare from '@/assets/icons/share.svg';
import IconWallet from '@/assets/icons/wallet.svg';
import IconLocation from '@/assets/icons/location.svg';
import IconCalendar from '@/assets/icons/calendar.svg';

const icons = {
  'arrow-down': IconArrowDown,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrow-up': IconArrowUp,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'chevron-up': IconChevronUp,
  close: IconClose,
  checkmark: IconCheckmark,
  ellipsis: IconEllipsis,
  envelope: IconEnvelope,
  expand: IconExpand,
  facebook: IconFacebook,
  github: IconGithub,
  'google-maps': IconGoogleMaps,
  instagram: IconInstagram,
  link: IconLink,
  linkedin: IconLinkedin,
  menu: IconMenu,
  phone: IconPhone,
  play: IconPlay,
  plus: IconPlus,
  return: IconReturn,
  x: IconX,
  youtube: IconYoutube,
  search: IconSearch,
  enter: IconEnter,
  upload: IconUpload,
  'logo-on-color': IconLogoOnColor,
  'logo-on-dark': IconLogoOnDark,
  'logo-on-white': IconLogoOnWhite,
  share: IconShare,
  wallet: IconWallet,
  location: IconLocation,
  calendar: IconCalendar,
  contract: IconContract,
};

export const IconNames = Object.keys(icons);

export const IconName = oneOf([...IconNames, undefined]);

export default {
  inheritAttrs: false,
  props: {
    name: IconName,
    iconClass: string(),
    size: number(),
    width: number(),
    height: number(),
  },
  computed: {
    svgIcon() {
      return icons[this.name];
    },
    computedWidth() {
      return this.width || this.size;
    },
    computedHeight() {
      return this.height || this.size;
    },
    hasSize() {
      return this.computedWidth && this.computedHeight;
    },
    classes() {
      return {
        full: this.hasSize,
      };
    },
    style() {
      if (this.hasSize) {
        return {
          width: `${this.computedWidth}px`,
          height: `${this.computedHeight}px`,
        };
      }
      return null;
    },
  },
};
