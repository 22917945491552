
import {
  oneOfType,
  string,
  nullable,
  oneOf,
  arrayOf,
  shape,
  object,
} from 'vue-types';
import buttonMixin from '@/mixins/button';
import { assetShape } from '~/constants/PropTypes';

export default {
  mixins: [buttonMixin],
  inheritAttrs: false,
  props: {
    tag: string().def('button'),
    href: oneOfType([string(), nullable()]),
    to: oneOfType([string(), nullable()]),
    hash: oneOfType([string(), nullable()]),
    target: oneOfType([string(), nullable()]),
    ariaLabel: oneOfType([string(), nullable()]),
    type: oneOf(['internal', 'external', 'file', 'submit', 'button']),
    locationEntry: arrayOf(object()),
    locationUrl: oneOfType([string(), nullable()]),
    asset: arrayOf(shape(assetShape).loose),
  },
  computed: {
    /**
     * This component can either render a button or an anchor tag.
     */
    component() {
      if (this.type === 'external' || this.type === 'file' || this.href) {
        return 'a';
      }

      if (this.type === 'internal' || this.to) {
        return 'NuxtLink';
      }

      return this.tag;
    },
    bindings() {
      const bindings = {};

      if (this.target) {
        bindings.target = this.target;
      }

      if (this.$attrs.disabled) bindings.disabled = this.$attrs.disabled;

      if (this.type === 'external' && this.locationUrl) {
        bindings.href = this.locationUrl;
        bindings.rel = 'external noreferrer noopener';
        bindings.target = '_blank';
        if (this.hash) {
          bindings.href = `${bindings.href}#${this.hash}`;
        }
      } else if (this.type === 'internal' && this.$first(this.locationEntry)) {
        bindings.to = this.$pathFromUrl(this.$first(this.locationEntry).url);
        if (this.hash) {
          bindings.to = `${bindings.to}#${this.hash}`;
        }
      } else if (this.type === 'internal') {
        bindings.to = this.to || `#${this.hash}`;
      } else if (this.type === 'file') {
        bindings.download = true;
        bindings.href = this.$first(this.asset)?.src;
        bindings.rel = 'enclosure noreferrer noopener';
        bindings.target = '_blank';
      } else if (this.href) {
        bindings.href = this.href;
        bindings.rel = 'external noreferrer noopener nofollow';
        bindings.target = '_blank';
      } else if (this.to) {
        bindings.to = this.to;
      }

      return { ...bindings, ...this.$attrs };
    },
  },
};
