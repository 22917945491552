
import { COOKIE_NAME, COOKIE_SETTINGS, COOKIE_VERSION } from '~/store';

export default {
  inheritAttrs: false,
  computed: {
    bgColor() {
      return this.$store.state.pageBgColor || 'bg-white';
    },
    hash() {
      return this.$route.hash;
    },
    scrollTrackables() {
      return this.$store.state.scroll.tracklist;
    },
  },
  watch: {
    hash(value) {
      if (value) {
        this.$nextTick(() => {
          setTimeout(() => {
            const element = document.querySelector(value);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 1000);
        });
      }
    },
    scrollTrackables(value) {
      if (value.length) {
        const valueToTrack = value[value.length - 1];
        if (valueToTrack) {
          this.$trackingEvent('event', `${valueToTrack}%`, {
            event_category: 'scroll',
            event_label: this.$store.state.route.fullPath,
            non_interaction: true,
          });
        }
      }
    },
  },
  mounted() {
    const cookie = this.$cookies.get(COOKIE_NAME);
    let consent = this.$store.state.cookieConsent;
    if (cookie) {
      const { version, consent: consentFromCookie } = cookie;

      if (version !== COOKIE_VERSION) {
        this.$cookies.remove(COOKIE_NAME, COOKIE_SETTINGS);
      } else {
        consent = consentFromCookie;
        this.$store.commit('cookieConsent', consent);
      }
    } else {
      this.$store.commit('askCookieConsent');
    }

    this.$trackingUpdate(consent);

    this.setupScroll();

    setTimeout(this.logEasterEgg, 3000);
  },
  beforeDestroy() {
    if (this.ro) this.ro.disconnect();
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    setupScroll() {
      this.ro = new ResizeObserver(this.scrollHandler);
      this.ro.observe(document.body);
      window.addEventListener('scroll', this.scrollHandler);
    },
    scrollHandler() {
      if (!this.$store.state.viewport.scrollingTo) {
        const scrollY = window.scrollY;
        const pct = Math.round(
          100 *
            (scrollY /
              (document.body.scrollHeight - this.$store.state.viewport.height))
        );

        this.$store.commit('scroll/update', pct);
        this.$store.commit('viewport/scroll', scrollY);
      }
    },
    logEasterEgg() {
      console.log(
        `
%c ███      %c  █████████  ███████████  ███████████
%c░░███     %c ███░░░░░███░░███░░░░░███░░███░░░░░███
%c ░░███    %c░███    ░░░  ░███    ░███ ░███    ░███
%c  ░░███   %c░░█████████  ░██████████  ░██████████
%c   ░░███  %c ░░░░░░░░███ ░███░░░░░███ ░███░░░░░░
%c    ░░███ %c ███    ░███ ░███    ░███ ░███
%c     ░░███%c░░█████████  ███████████  █████
%c      ░░░ %c ░░░░░░░░░  ░░░░░░░░░░░  ░░░░░
`,
        ...new Array(8)
          .fill('color: #1e80ed|color: white')
          .reduce((arr, v) => [...arr, ...v.split('|')], [])
      );
      console.log(
        "%cWe're looking for people who know their way around code! Check out our open positions: https://schubergphilis.com/en/careers",
        'display: inline-block; text-align: center; color: white; font-weight: bold; font-size: 14px; font-family: TTInterphasesMono, ui-monospace, monospace; padding: 24px; background-color: #1e80ed; border-radius: 8px;'
      );
    },
  },
};
