
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';

export const SlideType = shape({
  id: string(),
  visual: arrayOf(shape(imageShape).loose),
  heading: oneOfType([string(), nullable()]),
  description: oneOfType([string(), nullable()]),
}).loose;

export default {
  inheritAttrs: false,
  props: {
    slides: arrayOf(SlideType),
  },
};
