// project-specific
const CUSTOM_EASES = {
  e1: '.74, 0, .39, 1',
  e2: '.16, .16, .52, 1',
  e3: '.56, 0, .83, .83',
  e4: '.16, .16, .13, 1',
};

// TODO: how to move this to a util that can be used in the project as well...
function mapObject(obj, fn) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
  );
}

const CUSTOM = mapObject(CUSTOM_EASES, (value) => `cubic-bezier(${value})`);

module.exports = {
  transitionTimingFunction: {
    ...CUSTOM,
  },
  transitionDuration: {
    default: '300ms',
    80: '80ms',
    150: '150ms',
    200: '200ms',
    250: '250ms',
    300: '300ms',
    400: '400ms',
    500: '500ms',
  },
  transitionDelay: {
    0: '0ms',
    1: '0.08s',
    2: '0.16s',
    3: '0.24s',
    4: '0.32s',
    5: '0.40s',
    6: '0.48s',
  },
};
