
import { nullable, oneOf, oneOfType, string, number } from 'vue-types';
import { IconName } from '~/components/atoms/Icon.vue';
import {
  COLOR_SCHEME_BLACK,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_WHITE,
  SIZE_EXTRA_SMALL,
  SIZE_MEDIUM,
  SIZE_SMALL,
} from '~/constants/General';
export default {
  inheritAttrs: false,
  props: {
    size: oneOf([SIZE_SMALL, SIZE_MEDIUM, SIZE_EXTRA_SMALL]).def(SIZE_MEDIUM),
    iconSize: number(),
    color: oneOf([
      'soft-blue',
      'outline',
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_BLACK,
    ]).def(COLOR_SCHEME_BLUE),
    label: oneOfType([string(), nullable()]),
    icon: IconName.def('arrow-right'),
  },
  data() {
    return {
      SIZE_EXTRA_SMALL,
      SIZE_SMALL,
      SIZE_MEDIUM,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_BLACK,
    };
  },
};
