
import { bool } from 'vue-types';

const TRANSITION_DURATION = 300;

export default {
  props: {
    active: bool().def(false),
  },
  data() {
    return {
      render: false,
    };
  },
  watch: {
    active(value) {
      if (value) {
        this.render = true;
      } else {
        setTimeout(() => {
          this.render = false;
        }, TRANSITION_DURATION);
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('overlays/closeAll');
    },
  },
};
