
import { bool, number } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    progress: number(),
    ccw: bool().def(false), // counter-clockwise
  },
  data() {
    return {
      circumference: Math.PI * 36,
    };
  },
  computed: {
    progressStyle() {
      const direction = this.ccw ? -1 : 1;
      const offset = direction * (1 - this.progress) * this.circumference;

      return {
        'stroke-dasharray': `${this.circumference}px`,
        'stroke-dashoffset': `${offset}px`,
      };
    },
  },
};
