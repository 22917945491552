
import {
  arrayOf,
  bool,
  nullable,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { IconName } from '~/components/atoms/Icon.vue';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_WHITE,
  SIZE_MEDIUM,
  SIZE_SMALL,
} from '~/constants/General';
import { entryShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    border: bool().def(false),
    icon: IconName,
    disabled: bool().def(false),
    hasIcon: bool().def(true),
    size: oneOf([SIZE_SMALL, SIZE_MEDIUM]).def(SIZE_MEDIUM),
    color: oneOf([
      'soft-blue',
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_WHITE,
    ]).def(COLOR_SCHEME_BLUE),
    label: oneOfType([string(), nullable()]),
    locationEntry: arrayOf(shape(entryShape).loose),
    iconLeft: bool().def(false),
    type: oneOfType([string(), nullable()]),
  },
  data() {
    return {
      SIZE_SMALL,
      SIZE_MEDIUM,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_WHITE,
    };
  },
  computed: {
    buttonLabel() {
      if (this.label) {
        return this.label;
      }

      if (
        this.type === 'internal' &&
        this.$first(this.locationEntry) &&
        this.$first(this.locationEntry).title
      ) {
        return this.$first(this.locationEntry).title;
      }

      if (this.type === 'file') {
        return this.$t('Download');
      }

      return this.$t('Read more');
    },
  },
};
