const initialState = {
  video: {
    active: false,
    id: null,
    type: null,
    youtubeId: null,
    vimeoId: null,
    asset: null,
  },
  apply: {
    active: false,
    careerTitle: '',
    greenhouseId: '',
  },
  openings: {
    active: false,
    categoryId: null,
  },
  gallery: {
    active: false,
    slides: [],
  },
  careerStory: {
    active: false,
    title: '',
    visual: null,
    author: null,
    modules: [],
  },
};

export const state = () => initialState;

export const mutations = {
  video(state, value) {
    state.apply.active = initialState.apply.active;
    state.openings.active = initialState.openings.active;
    state.gallery.active = initialState.gallery.active;
    state.careerStory.active = initialState.careerStory.active;
    state.video = value;
  },
  apply(state, value) {
    state.video.active = initialState.video.active;
    state.openings.active = initialState.openings.active;
    state.gallery.active = initialState.gallery.active;
    state.careerStory.active = initialState.careerStory.active;
    state.apply = value;
  },
  openings(state, value) {
    state.video.active = initialState.video.active;
    state.apply.active = initialState.apply.active;
    state.gallery.active = initialState.gallery.active;
    state.careerStory.active = initialState.careerStory.active;
    state.openings = value;
  },
  gallery(state, value) {
    state.video.active = initialState.video.active;
    state.apply.active = initialState.apply.active;
    state.openings.active = initialState.openings.active;
    state.careerStory.active = initialState.careerStory.active;
    state.gallery = value;
  },
  careerStory(state, value) {
    state.video.active = initialState.video.active;
    state.apply.active = initialState.apply.active;
    state.openings.active = initialState.openings.active;
    state.gallery.active = initialState.gallery.active;
    state.careerStory = value;
  },
  closeAll(state) {
    state.video.active = initialState.video.active;
    state.apply.active = initialState.apply.active;
    state.openings.active = initialState.openings.active;
    state.gallery.active = initialState.gallery.active;
    state.careerStory.active = initialState.careerStory.active;
  },
};
