// import Vue from 'vue';
import unique from '@/utils/array/unique';

export default (context, inject) => {
  const first = (arr) => {
    if (!arr) return null;
    if (!Array.isArray(arr)) return arr;
    if (!arr.length) return null;

    return arr[0];
  };

  const last = (arr) => {
    if (!arr) return null;
    if (!Array.isArray(arr)) return arr;
    if (!arr.length) return null;

    return arr[arr.length - 1];
  };

  const pathFromUrl = (url) => {
    if (!url) {
      return '#';
    }

    if (URL && url.search(/^\//) === -1) {
      return new URL(url).pathname;
    }

    return url;
  };

  const subPathIsActive = (url) => {
    const path = url.replace(context.$config.SITE_URL, '');
    const currentPath = context.route.path.replace(/\/$/, '');

    // Home has to be an exact match.
    if (path.match(/^\/[a-z]{2}\/?$/)) {
      return currentPath === path;
    }

    // Other links are also active when a child page is active.
    return currentPath.indexOf(path) === 0;
  };

  // Inject in Vue, context and store.
  inject('first', first);
  inject('last', last);
  inject('unique', unique);
  inject('pathFromUrl', pathFromUrl);
  inject('subPathIsActive', subPathIsActive);
};
