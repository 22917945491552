import query from '@/gql/queries/globals.gql';

export const COOKIE_NAME = 'cookie-consent';
export const COOKIE_VERSION = 1.2;
export const COOKIE_SETTINGS = {
  maxAge: 60 * 60 * 24 * 365, // 1 year
  sameSite: 'strict',
  secure: !process.env.DEV,
  path: '/',
};

export const state = () => ({
  csrfToken: null,
  cookieConsentAsked: null,
  cookieConsent: {
    essential: true,
    analytics: false,
    advertising: false,
  },
  pageBgColor: 'bg-white',
  page: {},
  languageSwitch: {},

  contactPage: null,
  navItems: null,
  contactInformation: null,
  footer: null,
  cookieModal: null,
  openings: [],
  openApplications: [],

  enableTracking: true,
});

export const getters = {
  locale(state) {
    if (state.route && state.route.params && state.route.params.locale) {
      return state.route.params.locale.toLowerCase();
    }
    return 'nl';
  },
  site(state, getters) {
    return getters.locale || 'nl';
  },
};

export const mutations = {
  page(state, value) {
    state.page = value;
  },
  languageSwitch(state, value) {
    state.languageSwitch = {
      en: null,
      nl: null,
      ...value,
    };
  },
  csrfToken(state, value) {
    state.csrfToken = value;
  },
  askCookieConsent(state) {
    state.cookieConsentAsked = false;
  },
  cookieConsent(state, value) {
    state.cookieConsent = value;
    state.cookieConsentAsked = true;
  },
  pageBgColor(state, value) {
    state.pageBgColor = value;
  },
  setGlobals(state, value) {
    state.contactPage = value.nav.contactPage;
    state.navItems = value.nav.navItems;
    state.contactInformation = value.contactInformation;
    state.footer = value.footer;
    state.cookieModal = value.cookieModal;
    state.openings = value.openings;
    state.openApplications = value.openApplications;
  },
  enableTracking(state, value) {
    state.enableTracking = value;
  },
};

export const actions = {
  async nuxtServerInit({ commit }) {
    const globals = await this.$cms.query({
      query,
      variables: { site: this.$i18n.locale },
    });

    if (globals) {
      commit('setGlobals', globals);
    }
  },
  setCookieConsent({ commit }, consent) {
    if (process.client) {
      commit('cookieConsent', consent);

      this.$cookies.set(
        COOKIE_NAME,
        {
          version: COOKIE_VERSION,
          consent,
        },
        COOKIE_SETTINGS
      );

      if (typeof window !== 'undefined') {
        this.$trackingUpdate(consent);
      }
    }
  },
};
