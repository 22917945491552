
import { bool, nullable, oneOf, oneOfType, string } from 'vue-types';
import { COLOR_SCHEME_BLUE, COLOR_SCHEME_WHITE } from '~/constants/General';

export default {
  inheritAttrs: false,
  props: {
    name: string().isRequired,
    inputValue: string(),
    value: bool().def(false),
    error: oneOfType([string(), nullable()]),
    colorScheme: oneOf([COLOR_SCHEME_BLUE, COLOR_SCHEME_WHITE]).def(
      COLOR_SCHEME_BLUE
    ),
  },
  data() {
    const isDisabled =
      this.$attrs.disabled === '' || this.$attrs.disabled === true;

    return {
      isDisabled,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_BLUE,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
