
import { bool, object, string } from 'vue-types';
import { imageShape } from '@/constants/PropTypes';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';

export default {
  inheritAttrs: false,
  props: {
    imgClass: string(),
    sizes: object().def({ xs: '100vw' }),
    renderPlaceholder: bool().def(true),
    lazy: bool().def(true),
    cover: bool().def(true),
    ...imageShape,
  },
  data() {
    return {
      loaded: false,
      visible: false,
    };
  },
  computed: {
    isSvg() {
      return this.mimeType && this.mimeType === 'image/svg+xml';
    },
    sizesAttr() {
      return Object.keys(this.sizes)
        .map((breakpoint) => {
          const size = isNaN(this.sizes[breakpoint])
            ? this.sizes[breakpoint]
            : `${this.sizes[breakpoint]}px`;
          return breakpoint === 'xs'
            ? size
            : `(min-width: ${breakpoints[breakpoint]}px) ${size}`;
        })
        .reverse()
        .join(', ');
    },
    imgSrc() {
      return this.transforms && this.transforms.src.length
        ? this.transforms.src
        : this.src || null;
    },
    imgSrcset() {
      return this.transforms && this.transforms.srcset.length
        ? this.transforms.srcset
        : this.srcset || null;
    },
    hasTransforms() {
      return (
        typeof this.transforms !== 'undefined' && this.transforms.srcset.length
      );
    },
    usePlaceholder() {
      return (
        this.renderPlaceholder &&
        this.transforms &&
        this.transforms.placeholderImage
      );
    },
    focalX() {
      return this.focalPoint[0] * 100;
    },
    focalY() {
      return this.focalPoint[1] * 100;
    },
    hasFocal() {
      return !(this.focalX === 50 && this.focalY === 50);
    },
    pictureStyle() {
      return {
        backgroundImage:
          !this.visible && this.usePlaceholder
            ? `url(${this.transforms.placeholderImage})`
            : null,
        backgroundPosition: this.hasFocal
          ? `${this.focalX}% ${this.focalY}%`
          : 'center',
      };
    },
    imgStyle() {
      return {
        objectPosition: this.hasFocal
          ? `${this.focalX}% ${this.focalY}%`
          : null,
      };
    },
  },
  watch: {
    loaded(val) {
      if (val) {
        // This way the background placeholder is removed when the animation is done.
        setTimeout(() => {
          this.visible = true;
        }, 400);
      }
    },
  },
};
