export default {
  methods: {
    buttonClickHandler(e) {
      if (
        e.currentTarget.href &&
        (e.currentTarget.target === '_blank' ||
          e.currentTarget.href.indexOf('mailto:') === 0 ||
          e.currentTarget.href.indexOf('tel:') === 0)
      ) {
        this.$trackingEvent('event', 'outbound', {
          event_category: 'outbound',
          event_label: e.currentTarget.href,
        });
      }

      this.$emit('click', e);
    },
  },
};
