import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_TRANSPARENT,
  COLOR_SCHEME_WHITE,
} from '~/constants/General';
import HomepageJobTicker from '@/components/organisms/HomepageJobTicker.vue';

export default {
  components: {
    HomepageJobTicker,
  },
  props: {
    modules: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        type: oneOfType([string(), nullable()]),
      }).loose
    ),
  },
  methods: {
    calculateModulePositions(ankeilerHeader = false) {
      if (this.modules?.length) {
        const positions = [];
        const colorSchemes = {
          'bg-blue-500': COLOR_SCHEME_BLUE,
          'bg-blue-25': COLOR_SCHEME_LIGHT,
          'bg-white': COLOR_SCHEME_WHITE,
          'bg-transparent': COLOR_SCHEME_TRANSPARENT,
          'bg-dark-475': COLOR_SCHEME_DARK,
        };

        this.modules.forEach((module, i) => {
          if (i === 0) {
            positions.push({
              top: 0,
              colorScheme: this.$store.state.nav.colorScheme,
            });

            // An exception rule for the homepage introduction module
            if (module.type === 'homeIntroduction') {
              const element = this.$first(this.$refs[module.id])?.$el;
              if (element) {
                const header = element.querySelector('header');
                if (header) {
                  positions.push({
                    top: header.offsetHeight,
                    colorScheme: COLOR_SCHEME_BLUE,
                  });
                }
              }
              return;
            } else if (ankeilerHeader) {
              const element = document.querySelector('header picture');
              if (element) {
                positions.push({
                  top: element.getBoundingClientRect().top + window.scrollY,
                  colorScheme: COLOR_SCHEME_TRANSPARENT,
                });
              }
            }
          }

          const element = this.$first(this.$refs[module.id]);
          const { background } = this.getType(module);
          const colorScheme = colorSchemes[background] || COLOR_SCHEME_WHITE;

          if (element && element.$el && element.$el.getBoundingClientRect) {
            positions.push({
              top: element.$el.getBoundingClientRect().top + window.scrollY,
              colorScheme,
            });
          }
        });

        this.$store.commit('nav/pageColorPositions', positions);
      }
    },
    lastModuleBackground() {
      const last = this.$last(this.modules);

      if (last) {
        const background = this.getType(last)?.background;
        if (background) return background;
      }

      return 'bg-white';
    },

    getType(module) {
      const defaultOptions = {
        background: module.background || 'bg-white',
        topDivider:
          module.roundedCorners === 'top' ||
          module.roundedCorners === 'topBottom',
        topSpacing: false,
        bottomDivider:
          module.roundedCorners === 'bottom' ||
          module.roundedCorners === 'topBottom',
        bottomSpacing: false,
      };

      const moduleOptions = {
        ankeiler: {
          name: 'OrganismsModulesAnkeiler',
          background: 'bg-transparent',
          topSpacing: true,
          bottomSpacing: true,
        },
        ankeilerHalf: {
          name: 'OrganismsModulesAnkeilerHalf',
        },
        bundleSlider: {
          name: 'OrganismsStoriesBundleSlider',
        },
        storySlider: {
          name: 'OrganismsStorySlider',
        },
        caseList: {
          name: 'OrganismsModulesCaseList',
        },
        chapters: {
          name: 'OrganismsModulesChapters',
          bottomSpacing: true,
        },
        collage: {
          name: 'OrganismsModulesCollage',
        },
        columns: {
          name: 'OrganismsHomepageColumns',
          background: 'bg-blue-500',
        },
        contactExpert: {
          name: 'OrganismsModulesContactExpert',
        },
        customers: {
          name: 'OrganismsModulesCustomerMarquee',
        },
        customersHomepage: {
          name: 'OrganismsHomepageCustomerMarquee',
          background: 'bg-blue-500',
          bottomSpacing: true,
        },
        optIn: {
          name: 'OrganismsOptIn',
        },
        expertiseOverview: {
          name: 'OrganismsModulesExpertisesOverview',
        },
        expertisesWordCloud: {
          name: 'OrganismsModulesExpertisesWordCloud',
        },
        featuredStory: {
          name: 'OrganismsFeaturedStory',
        },
        goToPage: {
          name: 'OrganismsModulesGoToPage',
        },
        goToPageCareers: {
          name: 'OrganismsModulesGoToPageCareers',
        },
        goToPageCaseStudy: {
          name: 'OrganismsModulesGoToPageCaseStudy',
        },
        homeIntroduction: {
          name: 'OrganismsHomepageIntroduction',
          background: 'bg-blue-500',
        },
        imageSlider: {
          name: 'OrganismsImageSlider',
        },
        inlineImages: {
          name: 'OrganismsInlineImages',
        },
        introduction: {
          name: 'OrganismsModulesIntroduction',
        },
        instagram: {
          name: 'OrganismsModulesInstagram',
        },
        item: {
          name: 'MoleculesCardItems',
        },
        jobTicker: {
          name: HomepageJobTicker,
          background: 'bg-dark-475',
        },
        latestStories: {
          name: 'OrganismsLatestStories',
          bottomDivider: true,
        },
        partners: {
          name: 'OrganismsHomepagePartners',
          background: 'bg-white',
        },
        results: {
          name: 'MoleculesResultNumbers',
        },
        richtext: {
          name: 'OrganismsModulesRichText',
        },
        richtextColumns: {
          name: 'OrganismsModulesRichTextColumns',
        },
        textCustomers: {
          name: 'OrganismsModulesCustomersInline',
        },
        textImage: {
          name: 'OrganismsModulesTextImage',
        },
        textBox: {
          name: 'OrganismsModulesTextBox',
          topSpacing: true,
          bottomSpacing: true,
        },
        textBoxFoldout: {
          name: 'OrganismsModulesTextFoldout',
        },
        timeline: {
          name: 'OrganismsModulesTimeline',
          background: 'bg-white',
          topSpacing: true,
        },
        twitter: {
          name: 'MoleculesCardTwitter',
        },
        videoCard: {
          name: 'OrganismsVideoCard',
        },
        videoplayer: {
          name: 'OrganismsInlineVideo',
        },
        quickLink: {
          name: 'OrganismsModulesQuicklink',
        },
        quote: {
          name: 'OrganismsStreamer',
        },
        collapsibleList: {
          name: 'OrganismsModulesCollapsibleList',
        },
        flippingBook: {
          name: 'OrganismsModulesFlippingBook',
        },
      }[module.type];

      if (!moduleOptions && this.$config.DEV) {
        console.error('Missing module type:', module.type);
        return {};
      } else if (!moduleOptions) {
        this.$sentry.captureMessage(`Missing module type: ${module.type}`);
        return {};
      }

      return {
        ...defaultOptions,
        ...moduleOptions,
      };
    },
  },
};
