
import { array, string } from 'vue-types';
import { SIZE_SMALL } from '~/constants/General';

export default {
  inheritAttrs: false,
  props: {
    heading: string(),
    body: string(),
    acceptAllButtonLabel: string(),
    saveChoicesButtonLabel: string(),
    bottomText: string(),
    gdprList: array(),
  },
  data() {
    return { SIZE_SMALL, detail: false };
  },
  computed: {
    show() {
      return this.$store.state.cookieConsentAsked === false;
    },
  },
  methods: {
    accept() {
      this.$store.dispatch('setCookieConsent', {
        essential: true,
        analytics: true,
        advertising: true,
      });
    },
  },
};
