
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';
import { SIZE_MEDIUM } from '~/constants/General';

export default {
  inheritAttrs: false,
  data() {
    return {
      SIZE_MEDIUM,
    };
  },
  computed: {
    active() {
      return this.$store.state.overlays.video.active;
    },
    videoData() {
      return this.$store.state.overlays.video;
    },
  },
  watch: {
    active(value) {
      if (!this.$refs.overlay) {
        return;
      }

      if (value) {
        disableBodyScroll(this.$refs.overlay);
      } else {
        enableBodyScroll(this.$refs.overlay);
      }
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    close(e) {
      if (e) {
        e.stopPropagation();
      }

      this.$store.commit('overlays/video', { active: false });
    },
  },
};
