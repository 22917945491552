
import { Swiper, SwiperSlide } from 'swiper-vue2';
import { bool, oneOf } from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_WHITE,
  SIZE_MEDIUM,
} from '~/constants/General';
import { m, l } from '~/_tailwind/tailwind.config.breakpoints';

const s = 530;

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  inheritAttrs: false,
  props: {
    controls: bool().def(true),
    colorScheme: oneOf([COLOR_SCHEME_WHITE, COLOR_SCHEME_BLUE]).def(
      COLOR_SCHEME_BLUE
    ),
    maxVisible: oneOf([1, 2, 3]).def(3),
    controlPosition: oneOf(['bottom', 'side']).def('bottom'),
  },
  data() {
    return {
      COLOR_SCHEME_WHITE,
      SIZE_MEDIUM,
      prevDisabled: false,
      nextDisabled: false,
      slider: null,
      activeIndex: 0,
      progressSliderStyle: {},
      hiddenSlides: [],
      sliderOptions: {
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 400,
        breakpoints: {
          [s]: {
            slidesPerView: Math.min(this.maxVisible, 2),
            spaceBetween: 40,
          },
          [m]: {
            slidesPerView: Math.min(this.maxVisible, 3),
            spaceBetween: this.controlPosition === 'bottom' ? 40 : 144,
          },
          [l]: {
            slidesPerView: Math.min(this.maxVisible, 3),
            spaceBetween: this.controlPosition === 'bottom' ? 64 : 220,
          },
        },
      },
    };
  },
  computed: {
    controlClass() {
      return this.controlPosition === 'side'
        ? 'side-controls'
        : 'bottom-controls';
    },
    slideSlots() {
      return Object.keys(this.$slots).filter((slot) => slot !== 'slider-nav');
    },
    showControls() {
      return this.controls && this.maxVisible < this.slideSlots.length;
    },
  },
  methods: {
    initSlider(slider) {
      this.slider = slider;
      this.calculateHiddenSlides();
    },
    onSlideChange() {
      const { activeIndex } = this.slider;
      this.nextDisabled =
        activeIndex + this.slider.slidesPerViewDynamic() >=
        this.slideSlots.length;
      this.prevDisabled = activeIndex <= 0;
      this.activeIndex = activeIndex;
      this.calculateHiddenSlides();
      this.calculateProgressPosition();
    },
    calculateProgressPosition() {
      const slidesInView = this.slider.slidesPerViewDynamic();

      const maxDistance = 200;
      const step = maxDistance / (this.slideSlots.length - slidesInView);

      this.progressSliderStyle = {
        transform: `translateX(${Math.min(
          step * this.activeIndex,
          maxDistance
        )}%)`,
      };
    },
    calculateHiddenSlides() {
      const slidesInView = this.slider.slidesPerViewDynamic();
      const hiddenSlides = [];
      for (let i = 0; i < this.slideSlots.length; i++) {
        if (i < this.activeIndex) {
          hiddenSlides.push(i);
        }
        if (i > this.activeIndex + slidesInView - 1) {
          hiddenSlides.push(i);
        }
      }
      this.hiddenSlides = hiddenSlides;
    },
    next() {
      if (this.slider) {
        this.slider.slideNext();
      }
    },
    prev() {
      if (this.slider) {
        this.slider.slidePrev();
      }
    },
  },
};
