
import {
  arrayOf,
  nullable,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { COLOR_SCHEME_BLUE } from '~/constants/General';

export default {
  inheritAttrs: false,
  props: {
    vacancyCount: number(),
    contactPage: shape({
      id: oneOfType([string(), nullable()]),
      section: oneOfType([string(), nullable()]),
      title: oneOfType([string(), nullable()]),
    }).loose,
    navItems: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        entry: arrayOf(object()),
        sub: arrayOf(
          shape({
            id: oneOfType([string(), nullable()]),
            type: oneOfType([string(), nullable()]),
            heading: oneOfType([string(), nullable()]),
            navItems: arrayOf(object()),
            image: arrayOf(object()),
          }).loose
        ),
      }).loose
    ),
  },
  computed: {
    colorScheme() {
      return this.$store.state.nav.colorScheme || COLOR_SCHEME_BLUE;
    },
  },
};
