
import { object, string } from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    modal: object(),
    buttonLabel: string(),
  },
  data() {
    return {
      consentValues: ['essential'],
      defaultDisabled: ['essential'],
    };
  },
  computed: {
    gdpr() {
      return this.modal.gdprList.map(this.mapGDPRField) || [];
    },
  },
  methods: {
    mapGDPRField(field) {
      const type = field.type.replace('Field', '').toLowerCase();

      return {
        ...field,
        type,
        checked: this.consentValues[type] === true,
        disabled: this.defaultDisabled.includes(type),
      };
    },
    click() {
      const data = this.gdpr.reduce((acc, value) => {
        return {
          ...acc,
          [value.type]: this.consentValues.includes(value.type),
        };
      }, {});
      this.$store.dispatch('setCookieConsent', data);
      // this.$emit('accept'); // Don't emit because it will overwrite specifics
    },
  },
};
