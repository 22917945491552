
import {
  arrayOf,
  bool,
  nullable,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { IconName } from './Icon.vue';
import { SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE } from '@/constants/General';
import { entryShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    label: oneOfType([string(), nullable()]),
    locationEntry: arrayOf(shape(entryShape).loose),
    type: oneOfType([string(), nullable()]),
    dark: bool().def(false),
    light: bool().def(false),
    colorless: bool().def(false),
    size: oneOf([SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE]).def(SIZE_MEDIUM),
    rtl: bool().def(false),
    icon: IconName.def('arrow-right'),
  },
  data() {
    return {
      SIZE_LARGE,
      SIZE_SMALL,
    };
  },
  computed: {
    iconName() {
      if (this.icon) {
        return this.icon;
      }
      if (this.size === SIZE_LARGE) {
        return 'chevron-right';
      }
      return 'arrow-right';
    },
    iconSize() {
      if (this.size === SIZE_MEDIUM) {
        return 20;
      }
      return 16;
    },
    iconClasses() {
      return {
        'text-blue-500':
          (this.size !== SIZE_LARGE && !this.dark) ||
          (this.size === SIZE_LARGE && this.light),
        'text-turquoise-500': this.size !== SIZE_LARGE && this.dark,
        'ml-4': !this.rtl && this.size !== SIZE_LARGE,
        'mr-4': this.rtl && this.size !== SIZE_LARGE,
        'text-white': this.size === SIZE_LARGE && !this.light,
        [`
              ml-16
              large-icon
              ${this.colorless ? 'colorless' : ''}
              ${this.light ? 'light' : ''}
            `]: this.size === SIZE_LARGE,
      };
    },
    buttonLabel() {
      if (this.label) {
        return this.label;
      }

      if (
        this.type === 'internal' &&
        this.$first(this.locationEntry) &&
        this.$first(this.locationEntry).title
      ) {
        return this.$first(this.locationEntry).title;
      }

      if (this.type === 'file') {
        return this.$t('Download');
      }

      return this.$t('Read more');
    },
  },
};
