
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    category: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        title: oneOfType([string(), nullable()]),
        colorName: oneOfType([string(), nullable()]),
      }).loose
    ),
    location: arrayOf(
      shape({
        id: string(),
        title: string(),
      }).loose
    ),
    title: oneOfType([string(), nullable()]),
    openApplication: bool().def(false),
    url: oneOfType([string(), nullable()]),
  },
  computed: {
    to() {
      return this.localePath(this.$pathFromUrl(this.url));
    },
    categoryLabel() {
      return this.category.map((category) => category.title).join(' · ');
    },
  },
};
