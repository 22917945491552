import { COLOR_SCHEME_BLUE } from '~/constants/General';

export const state = () => ({
  pageColorPositions: [],
  colorScheme: COLOR_SCHEME_BLUE,
});

export const getters = {};

export const mutations = {
  pageColorPositions(state, value) {
    state.pageColorPositions = value;
  },
  colorScheme(state, value) {
    state.colorScheme = value;
  },
};

export const actions = {};
