
import { arrayOf, nullable, oneOf, oneOfType, shape, string } from 'vue-types';
import {
  VIDEO_TYPE_FILE,
  VIDEO_TYPE_VIMEO,
  VIDEO_TYPE_YOUTUBE,
} from '~/constants/General';
import { assetShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    type: oneOf([VIDEO_TYPE_VIMEO, VIDEO_TYPE_YOUTUBE, VIDEO_TYPE_FILE]),
    youtubeId: oneOfType([string(), nullable()]),
    vimeoId: oneOfType([string(), nullable()]),
    asset: arrayOf(shape(assetShape).loose),
  },
  data() {
    return {
      isVisible: false,
      ready: false,
    };
  },
  computed: {
    videoAsset() {
      return this.$first(this.asset);
    },
    isVimeo() {
      return this.type === VIDEO_TYPE_VIMEO && this.vimeoId;
    },
    isYoutube() {
      return this.type === VIDEO_TYPE_YOUTUBE && this.youtubeId;
    },
    isFile() {
      return (
        this.type === VIDEO_TYPE_FILE &&
        this.$first(this.asset) &&
        this.$first(this.asset).src
      );
    },
    playerSource() {
      if (this.type === VIDEO_TYPE_VIMEO) {
        return this.vimeoId;
      }

      if (this.type === VIDEO_TYPE_YOUTUBE) {
        return this.youtubeId;
      }
      const asset = this.$first(this.asset);
      return this.isVisible ? asset?.src : '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  beforeDestroy() {
    if (this.IntersectionObserver) this.IntersectionObserver.disconnect();
  },
  methods: {
    videoPlayerReady({ target }) {
      this.duration = Math.floor(target.duration);

      this.ready = true;
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '25px',
        threshold: 0,
      };

      this.IntersectionObserver = new IntersectionObserver((entries) => {
        const intersecting = !!this.$first(entries).isIntersecting;

        if (intersecting && !this.isVisible) {
          this.$nextTick(() => {
            this.isVisible = true;
          });
        }
      }, options);

      this.IntersectionObserver.observe(this.$refs.container);
    },
  },
};
