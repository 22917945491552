
import {
  arrayOf,
  nullable,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import {
  CAREER_OVERVIEW_ENTRY_TYPE,
  STORY_OVERVIEW_ENTRY_TYPE,
} from '@/constants/Cms';
import {
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_WHITE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_TRANSPARENT,
  COLOR_SCHEME_COLOR,
} from '~/constants/General';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';
import { imageShape } from '~/constants/PropTypes';

const NAV_HEIGHT = 80;

export default {
  inheritAttrs: false,
  props: {
    vacancyCount: number(),
    navItems: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        entry: arrayOf(
          shape({
            id: oneOfType([string(), nullable()]),
            section: oneOfType([string(), nullable()]),
            title: oneOfType([string(), nullable()]),
          }).loose
        ),
        sub: arrayOf(
          shape({
            id: oneOfType([string(), nullable()]),
            type: oneOfType([string(), nullable()]),
            entry: arrayOf(
              shape({
                heading: oneOfType([string(), nullable()]),
                title: oneOfType([string(), nullable()]),
                section: oneOfType([string(), nullable()]),
                overviewImage: arrayOf(shape(imageShape).loose),
              }).loose
            ),
            navItems: arrayOf(
              shape({
                id: oneOfType([string(), nullable()]),
                section: oneOfType([string(), nullable()]),
                title: oneOfType([string(), nullable()]),
              }).loose
            ),
          }).loose
        ),
      }).loose
    ).def([]),
    colorScheme: oneOf([
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_TRANSPARENT,
    ]).def(COLOR_SCHEME_BLUE),
    contactPage: shape({
      id: oneOfType([string(), nullable()]),
      section: oneOfType([string(), nullable()]),
      title: oneOfType([string(), nullable()]),
    }).loose,
  },
  data() {
    return {
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_LIGHT,
      CAREER_OVERVIEW_ENTRY_TYPE,
      STORY_OVERVIEW_ENTRY_TYPE,
      COLOR_SCHEME_TRANSPARENT,
      activeSubnav: null,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    bgLightness() {
      if (
        this.colorScheme === COLOR_SCHEME_WHITE ||
        this.colorScheme === COLOR_SCHEME_LIGHT
      ) {
        return COLOR_SCHEME_WHITE;
      }

      if (
        this.colorScheme === COLOR_SCHEME_TRANSPARENT ||
        this.colorScheme === COLOR_SCHEME_DARK
      ) {
        return COLOR_SCHEME_DARK;
      }

      return COLOR_SCHEME_COLOR;
    },
    bgClasses() {
      return {
        [COLOR_SCHEME_WHITE]: 'bg-white',
        [COLOR_SCHEME_LIGHT]: 'bg-blue-50',
        [COLOR_SCHEME_DARK]: 'bg-dark-475',
        [COLOR_SCHEME_BLUE]: 'bg-blue-500',
        [COLOR_SCHEME_TRANSPARENT]: 'transparent',
      }[this.colorScheme];
    },
    hoverColor() {
      return this.colorScheme === COLOR_SCHEME_WHITE ||
        this.colorScheme === COLOR_SCHEME_LIGHT
        ? 'text-blue-500'
        : 'text-turquoise-500';
    },
    scrolled() {
      return this.$store.state.viewport.scrollY >= 1;
    },
    scrollY() {
      return this.$store.state.viewport.scrollY;
    },
  },
  watch: {
    /**
     * When route changes, always close flyout
     */
    currentRoute() {
      this.activeSubnav = null;
    },
    scrollY(value) {
      if (this.$store.state.viewport.width >= breakpoints.m) {
        const positions = this.$store.state.nav.pageColorPositions;

        for (let i = positions.length - 1; i >= 0; i--) {
          if (value + NAV_HEIGHT >= positions[i].top) {
            const toSetColorScheme = positions[i].colorScheme;
            if (toSetColorScheme !== this.colorScheme) {
              this.$store.commit('nav/colorScheme', toSetColorScheme);
            }
            break;
          }
        }
      }
    },
  },
  methods: {
    onHover(id) {
      this.activeSubnav = id;
    },
    onLeave() {
      this.activeSubnav = null;
    },
    onTouchStart(e) {
      const link = e.target;

      // on touch device, when there's a subnav, prevent default and show subnav
      if (link?.classList?.contains('has-sub-links')) {
        const id = link.getAttribute('data-sub-id');

        e.preventDefault();
        e.stopPropagation();
        this.activeSubnav = id || null;
      }
    },
  },
};
