import { render, staticRenderFns } from "./NavDesktop.vue?vue&type=template&id=40fce9fe&scoped=true"
import script from "./NavDesktop.vue?vue&type=script&lang=js"
export * from "./NavDesktop.vue?vue&type=script&lang=js"
import style0 from "./NavDesktop.vue?vue&type=style&index=0&id=40fce9fe&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fce9fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsLogo: require('/src/components/atoms/Logo.vue').default,MoleculesNavFlyout: require('/src/components/molecules/nav/Flyout.vue').default,MoleculesLocaleSwitch: require('/src/components/molecules/LocaleSwitch.vue').default})
