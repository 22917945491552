
import {
  arrayOf,
  nullable,
  bool,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    open: bool().def(false),
    parent: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        section: oneOfType([string(), nullable()]),
        title: oneOfType([string(), nullable()]),
      }).loose
    ),
    items: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        type: oneOf(['links', 'featured']),
        heading: oneOfType([string(), nullable()]),
        entry: arrayOf(
          shape({
            heading: oneOfType([string(), nullable()]),
            title: oneOfType([string(), nullable()]),
            section: oneOfType([string(), nullable()]),
            overviewImage: arrayOf(shape(imageShape).loose),
          }).loose
        ),
        entries: arrayOf(
          shape({
            id: string(),
            section: string(),
            title: string(),
          }).loose
        ),
        careerCategories: arrayOf(
          shape({
            id: string(),
            url: string(),
            title: string(),
          }).loose
        ),
      }).loose
    ),
  },
  data() {
    return {};
  },
  methods: {
    renderFullSubnavMainLink(sub) {
      return (
        sub.length > 1 && sub[0].type === 'links' && sub[1].type === 'links'
      );
    },
  },
};
