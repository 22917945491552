import { GraphQLClient } from 'graphql-request';

export default ({ app, route, $config }) => {
  const previewToken = route?.query?.token || null;

  const { GQL_ENDPOINT } = $config;

  const graphQLClient = new GraphQLClient(
    previewToken ? `${GQL_ENDPOINT}?token=${previewToken}` : GQL_ENDPOINT,
    { mode: 'cors' }
  );

  // Allow access to the provider in the context
  app.graphQLClient = graphQLClient;
};
