
import {
  arrayOf,
  nullable,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import {
  HOMEPAGE_SECTION,
  CAREER_OVERVIEW_ENTRY_TYPE,
  STORY_OVERVIEW_ENTRY_TYPE,
} from '@/constants/Cms';
import {
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_WHITE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_TRANSPARENT,
  COLOR_SCHEME_COLOR,
} from '~/constants/General';

export default {
  inheritAttrs: false,
  props: {
    vacancyCount: number(),
    navItems: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        entry: arrayOf(
          shape({
            id: oneOfType([string(), nullable()]),
            section: oneOfType([string(), nullable()]),
            title: oneOfType([string(), nullable()]),
          }).loose
        ),
      }).loose
    ).def([]),
    colorScheme: oneOf([
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_TRANSPARENT,
    ]).def(COLOR_SCHEME_BLUE),
    contactPage: shape({
      id: oneOfType([string(), nullable()]),
      section: oneOfType([string(), nullable()]),
      title: oneOfType([string(), nullable()]),
    }).loose,
  },
  data() {
    return {
      HOMEPAGE_SECTION,
      COLOR_SCHEME_TRANSPARENT,
      CAREER_OVERVIEW_ENTRY_TYPE,
      STORY_OVERVIEW_ENTRY_TYPE,
      isOpen: false,
      locationOverlayOpen: false,
      activeSubnav: null,
    };
  },
  computed: {
    navItemsList() {
      return [
        {
          id: 'homepage',
          entry: [
            {
              id: 'homepage-entry',
              type: 'homepage',
              url: this.localePath('/'),
              title: 'Home',
            },
          ],
        },
        ...this.navItems,
        {
          entry: [this.contactPage],
        },
      ];
    },
    contactInfo() {
      return this.$store.state.contactInformation;
    },
    mainMapsUrl() {
      if (this.$store.state.contactInformation?.addresses?.length === 1) {
        return this.createMapsUrl(
          this.$store.state.contactInformation?.addresses[0].address
        );
      }
      return null;
    },
    isHome() {
      return this.$store.state.page?.section === HOMEPAGE_SECTION;
    },
    currentPageId() {
      return this.$store.state.page?.id;
    },
    bgLightness() {
      if (
        this.colorScheme === COLOR_SCHEME_WHITE ||
        this.colorScheme === COLOR_SCHEME_LIGHT
      ) {
        return COLOR_SCHEME_WHITE;
      }

      if (
        this.colorScheme === COLOR_SCHEME_TRANSPARENT ||
        this.colorScheme === COLOR_SCHEME_DARK
      ) {
        return COLOR_SCHEME_DARK;
      }

      return COLOR_SCHEME_COLOR;
    },
    bgColor() {
      return {
        COLOR_SCHEME_LIGHT: 'bg-white',
        COLOR_SCHEME_WHITE: 'bg-blue-50',
        COLOR_SCHEME_DARK: 'bg-dark-475',
        COLOR_SCHEME_BLUE: 'bg-blue-500',
        COLOR_SCHEME_TRANSPARENT: 'bg-dark-475',
      }[this.colorScheme];
    },
    textColor() {
      return this.colorScheme === COLOR_SCHEME_WHITE ||
        this.colorScheme === COLOR_SCHEME_LIGHT
        ? 'text-blue-950'
        : 'text-white';
    },
    hoverColor() {
      return this.colorScheme === COLOR_SCHEME_WHITE ||
        this.colorScheme === COLOR_SCHEME_LIGHT
        ? 'text-blue-500'
        : 'text-turquoise-500';
    },
    scrolled() {
      return this.$store.state.viewport.scrollY >= 1;
    },
  },
  methods: {
    createMapsUrl(address) {
      if (address && typeof address === 'string') {
        return `https://www.google.com/maps/place/${address
          .replace(/\n/g, ',+')
          .replace(/ /g, '+')}`;
      }

      return null;
    },
    onLocationClick(e) {
      if (this.contactInfo.addresses.length > 1) {
        e.preventDefault();
        this.locationOverlayOpen = !this.locationOverlayOpen;
      }
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      this.locationOverlayOpen = false;
    },
    onClose() {
      this.isOpen = false;
      this.locationOverlayOpen = false;

      setTimeout(() => {
        this.activeSubnav = null;
      }, 400);
    },
    onOpenSub(id) {
      this.activeSubnav = id || null;
    },
  },
};
