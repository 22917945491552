
import { arrayOf, bool } from 'vue-types';
import { COLOR_SCHEME_WHITE } from '~/constants/General';
import { SlideType } from '@/components/organisms/ImageSlider.vue';

export default {
  props: {
    active: bool().def(false),
    slides: arrayOf(SlideType).def([]),
  },
  data() {
    return {
      COLOR_SCHEME_WHITE,
    };
  },
  computed: {
    isActive() {
      return this.active || this.$store.state.overlays.gallery?.active || false;
    },
    cSlides() {
      return this.slides?.length
        ? this.slides
        : this.$store.state.overlays.gallery?.slides || [];
    },
  },
};
