
import { arrayOf, nullable, object, oneOfType, string } from 'vue-types';

const ITEMS_PER_SLIDE = 3;
const INTERVAL = 5; // in seconds

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    link: arrayOf(object()),
  },
  data() {
    return {
      currentSlide: 0,
      slideProgress: 0,
      IntersectionObserver: null,
      progressInverted: false,
      hovering: false,
    };
  },
  computed: {
    careers() {
      return this.$store.state.openings || [];
    },
    categories() {
      const idSet = new Set();
      return this.careers.reduce((categories, career) => {
        const category = this.$first(career.category);
        if (category && !idSet.has(category.id)) {
          idSet.add(category.id);
          return [...categories, category];
        }
        return categories;
      }, []);
    },
    count() {
      return this.careers?.length || 0;
    },
    numSlides() {
      return Math.ceil(this.count / ITEMS_PER_SLIDE);
    },
    firstCategories() {
      return this.categories.slice(0, -1);
    },
  },
  mounted() {
    this.tweenProgress();
    this.initIntersectionObserver();
  },
  beforeDestroy() {
    if (this.slideTween) {
      this.slideTween.kill();
    }
  },
  methods: {
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '25px',
        threshold: 0,
      };

      this.IntersectionObserver = new IntersectionObserver((entries) => {
        const intersecting = !!this.$first(entries).isIntersecting;

        if (intersecting) {
          this.play();
        } else {
          this.pause();
        }
      }, options);

      this.IntersectionObserver.observe(this.$refs.wrapper);
    },
    pause() {
      if (this.slideTween && !this.slideTween.paused()) {
        this.slideTween.pause();
      }
    },
    play() {
      if (this.slideTween && this.slideTween.paused()) {
        this.slideTween.resume();
      }
    },
    tweenProgress(play = false) {
      this.slideTween = this.$gsap.fromTo(
        this,
        {
          slideProgress: 0,
        },
        {
          slideProgress: 1,
          ease: 'linear',
          duration: INTERVAL,
          paused: !play,
          onComplete: () => {
            this.transitionToNextBatch();
          },
        }
      );
    },
    transitionToNextBatch() {
      const next = this.currentSlide + 1;
      this.currentSlide = next >= this.numSlides ? 0 : next;

      this.progressInverted = true;
      this.$gsap.to(this, {
        slideProgress: 0,
        ease: 'e1',
        duration: 0.5,
        onComplete: () => {
          this.progressInverted = false;
          this.tweenProgress(true);
        },
      });
    },
  },
};
