
import { bool, nullable, oneOf, oneOfType, string } from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    background: oneOfType([string(), nullable()]).def('bg-white'),
    hasDivider: bool().def(false),
    roundedCorners: oneOf(['', 'top', 'bottom', 'topBottom']),
    prevBg: oneOfType([string(), nullable()]),
    prevDivider: bool().def(false),
    prevSpacing: bool().def(false),
    nextBg: oneOfType([string(), nullable()]),
    nextDivider: bool().def(false),
    nextSpacing: bool().def(false),
  },
  computed: {
    topRounded() {
      return (
        this.roundedCorners === 'top' || this.roundedCorners === 'topBottom'
      );
    },
    bottomRounded() {
      return (
        this.roundedCorners === 'bottom' || this.roundedCorners === 'topBottom'
      );
    },
  },
};
