import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=6c1cd862"
import script from "./Gallery.vue?vue&type=script&lang=js"
export * from "./Gallery.vue?vue&type=script&lang=js"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=6c1cd862&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsImageSlider: require('/src/components/organisms/ImageSlider.vue').default,OrganismsOverlayModal: require('/src/components/organisms/overlay/Modal.vue').default})
