
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade';
import { bool } from 'vue-types';

export default {
  props: {
    active: bool().def(false),
  },
  watch: {
    active(value) {
      if (!this.$refs.overlay) {
        return;
      }

      if (value) {
        disableBodyScroll(this.$refs.overlay, { allowTouchMove: () => true });
      } else {
        enableBodyScroll(this.$refs.overlay);
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp);
    clearAllBodyScrollLocks();
  },
  methods: {
    onClick(event) {
      if (event.target === this.$refs.overlay) {
        this.$store.commit('overlays/closeAll');
      }
    },
    onKeyUp(event) {
      if (event.key === 'Escape') {
        this.$store.commit('overlays/closeAll');
      }
    },
  },
};
