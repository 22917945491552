
import {
  bool,
  shape,
  arrayOf,
  oneOfType,
  string,
  nullable,
  oneOf,
} from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
export default {
  props: {
    open: bool().def(false),
    parent: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        section: oneOfType([string(), nullable()]),
        title: oneOfType([string(), nullable()]),
      }).loose
    ),
    items: arrayOf(
      shape({
        id: oneOfType([string(), nullable()]),
        type: oneOf(['links', 'featured']),
        heading: oneOfType([string(), nullable()]),
        entry: arrayOf(
          shape({
            heading: oneOfType([string(), nullable()]),
            title: oneOfType([string(), nullable()]),
            section: oneOfType([string(), nullable()]),
            overviewImage: arrayOf(shape(imageShape).loose),
          }).loose
        ),
        entries: arrayOf(
          shape({
            id: string(),
            section: string(),
            title: string(),
          }).loose
        ),
        careerCategories: arrayOf(
          shape({
            id: string(),
            url: string(),
            title: string(),
          }).loose
        ),
      }).loose
    ),
  },
  data() {
    return {
      leftPosition: 0,
      transitionTimeout: 0,
    };
  },
  watch: {
    open(value) {
      if (value && this.$refs.container) {
        this.calculateLeftPosition();
      }
    },
  },
  methods: {
    calculateLeftPosition() {
      const container = this.$refs.container.getBoundingClientRect();
      const parent = this.$refs.container.parentNode.getBoundingClientRect();
      const width = (container.width / 0.9) * 1;
      const prefLeft = width / 2 - parent.width / 2;

      const fitsLeft = prefLeft <= parent.x;
      const fitsRight = parent.x + (width - prefLeft) <= window.innerWidth;

      if (fitsLeft && fitsRight) {
        this.leftPosition = -1 * prefLeft;
      } else if (fitsRight) {
        this.leftPosition = -1 * parent.x;
      } else {
        this.leftPosition = window.innerWidth - width - parent.x;
      }
    },
    renderFullSubnavMainLink(sub) {
      return (
        sub.length > 1 && sub[0].type === 'links' && sub[1].type === 'links'
      );
    },
  },
};
