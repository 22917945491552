
import { string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    kind: string().isRequired,
  },
  computed: {
    visualComponent() {
      if (this.kind === 'image') {
        return 'AtomsImage';
      }
      if (this.kind === 'video') {
        return 'AtomsStaticVideo';
      }

      return null;
    },
  },
};
