
export default {
  computed: {
    active() {
      return this.$store.state.overlays.careerStory?.active || false;
    },
    title() {
      return this.$store.state.overlays.careerStory?.title;
    },
    visual() {
      return this.$store.state.overlays.careerStory?.visual;
    },
    author() {
      return this.$store.state.overlays.careerStory?.author;
    },
    modules() {
      return this.$store.state.overlays.careerStory?.modules || [];
    },
  },
};
