import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=065e344a&scoped=true"
import script from "./Link.vue?vue&type=script&lang=js"
export * from "./Link.vue?vue&type=script&lang=js"
import style0 from "./Link.vue?vue&type=style&index=0&id=065e344a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065e344a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesIconWithBackslash: require('/src/components/molecules/IconWithBackslash.vue').default,AtomsButton: require('/src/components/atoms/Button.vue').default})
